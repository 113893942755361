import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Title70, Title26, Eyebrow } from 'components/Typography'
import { FadeInUpAnimationWrapper } from 'styles/animation'

import {
  HeroContainer,
  HeroWrapper,
  HeroTitle,
  HeroEyebrow,
  HeroDescription,
  SubtitleContainer,
  ImagesContainer,
  FeaturedImage,
  SecondaryImage,
  PrimarySecondarySpacer,
  HeroBottomCurvedEdge,
} from './LifestyleHero.style'

const LifestyleHero = ({
  pageTitle,
  pageSubtitle,
  publishDate,
  pageAuthorTagline,
  pageDescription,
  pageHexCodeValuePrimary,
  pageHexCodeValueSecondary,
  featuredImage,
  secondaryImage,
}) => {
  const [secondaryImageScale, setSecondaryImageScale] = React.useState(1)
  const [primaryImageScale, setPrimaryImageScale] = React.useState(1)

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset <= 300) {
        setSecondaryImageScale(window.pageYOffset / 1200 + 1)
      } else if (secondaryImageScale < 1.25) {
        setSecondaryImageScale(1.25)
      }

      if (window.pageYOffset <= 300) {
        setPrimaryImageScale(1 - window.pageYOffset / 1200)
      } else if (primaryImageScale > 0.75) {
        setPrimaryImageScale(0.75)
      }
    }

    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <HeroContainer>
      <HeroWrapper backgroundColor={pageHexCodeValuePrimary}>
        <Title70 as={HeroTitle}>{pageTitle}</Title70>
        <SubtitleContainer>
          <Eyebrow as={HeroEyebrow}>
            {pageSubtitle} &#8226; {publishDate} &#8226; {pageAuthorTagline}
          </Eyebrow>
        </SubtitleContainer>
        <ImagesContainer pageHexCodeValueSecondary={pageHexCodeValueSecondary}>
          {secondaryImage?.portrait && (
            <FadeInUpAnimationWrapper enabled>
              <SecondaryImage
                id="secondaryImage"
                image={secondaryImage?.portrait?.gatsbyImageData}
                alt={secondaryImage?.altText || ''}
                shadowColor={pageHexCodeValueSecondary}
                style={{
                  transform: `scale(${secondaryImageScale})`,
                }}
              />
            </FadeInUpAnimationWrapper>
          )}
          <PrimarySecondarySpacer />
          {featuredImage?.portrait && (
            <FadeInUpAnimationWrapper enabled>
              <FeaturedImage
                id="featuredImage"
                image={featuredImage?.portrait?.gatsbyImageData}
                alt={featuredImage?.altText || ''}
                shadowColor={pageHexCodeValueSecondary}
                style={{
                  transform: `scale(${primaryImageScale})`,
                }}
              />
            </FadeInUpAnimationWrapper>
          )}
        </ImagesContainer>
      </HeroWrapper>
      <HeroBottomCurvedEdge />

      <Title26 as={HeroDescription}>{pageDescription}</Title26>
    </HeroContainer>
  )
}

LifestyleHero.propTypes = {
  pageTitle: PropTypes.string,
  pageSubtitle: PropTypes.string,
  publishDate: PropTypes.string,
  pageAuthorTagline: PropTypes.string,
  pageDescription: PropTypes.string,
  pageHexCodeValuePrimary: PropTypes.string,
  pageHexCodeValueSecondary: PropTypes.string,
  featuredImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  secondaryImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

LifestyleHero.defaultProps = {
  pageTitle: '',
  pageSubtitle: '',
  publishDate: '',
  pageAuthorTagline: '',
  pageDescription: '',
  pageHexCodeValuePrimary: '',
  pageHexCodeValueSecondary: '',
}

export default LifestyleHero
