/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import LifestyleHero from 'components/LifestyleHero'
import { ReducedTopPaddedPage } from 'styles/sharedStyle'

// nie ma tej funkcji w componentRenderer
import renderContent from 'utils/componentRenderer'

import { decorateListicleContent, groupContentTypes } from 'utils/contentUtils'

const LifestylePageTemplateT17 = (props) => {
  const {
    data: {
      page: {
        content,
        title,
        description,
        pageSubtitle,
        publishDate,
        pageAuthorTagline,
        pageHexCodeValuePrimary,
        pageHexCodeValueSecondary,
        pageHexCodeValueTertiary,
        featuredImage,
        secondaryImage,
      },
    },
  } = props

  const processedContent = content
    ? decorateListicleContent(groupContentTypes(content))
    : []

  const ContentWrapper = ReducedTopPaddedPage

  return (
    <Layout {...props}>
      <LifestyleHero
        pageTitle={title}
        pageSubtitle={pageSubtitle}
        publishDate={publishDate}
        pageAuthorTagline={pageAuthorTagline}
        pageDescription={description}
        pageHexCodeValuePrimary={pageHexCodeValuePrimary}
        pageHexCodeValueSecondary={pageHexCodeValueSecondary}
        pageHexCodeValueTertiary={pageHexCodeValueTertiary}
        featuredImage={featuredImage}
        secondaryImage={secondaryImage}
      />
      <ContentWrapper>{renderContent(processedContent, props)}</ContentWrapper>
    </Layout>
  )
}

LifestylePageTemplateT17.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const lifestylePageT17Query = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $pageTypeSlug: String
    $villageSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateLifestyleT17(id: { eq: $id }) {
      __typename
      locale: node_locale
      content {
        __typename
        ... on Node {
          ...multipleComponents
        }
      }
      village {
        name
        code
        villageSlug: slug
        currency
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
        openingStatusLabel
      }
      title: pageTitle
      pageSubtitle
      publishDate(formatString: "D MMMM YYYY", locale: $nodeLocale)
      pageAuthorTagline
      description: pageDescription
      pageTitle: metaTitle
      pageDescription: metaDescription
      pageHexCodeValuePrimary
      pageHexCodeValueSecondary
      pageHexCodeValueTertiary
      featuredImage {
        ... on ContentfulEntityImageEnt02 {
          ...ResponsiveImageQuery
        }
        ... on ContentfulEntityImageSingleEnt10 {
          ...ResponsiveSingleImageQuery
        }
      }
      secondaryImage {
        ... on ContentfulEntityImageEnt02 {
          ...ResponsiveImageQuery
        }
        ... on ContentfulEntityImageSingleEnt10 {
          ...ResponsiveSingleImageQuery
        }
      }
      positionFromRightSide
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default LifestylePageTemplateT17
