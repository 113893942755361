import styled from 'styled-components'
import theme from 'styles/theme'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BottomWhiteCurvedEdge } from 'styles/sharedStyle'

const WidthThresholdMediaQuery = '@media screen and (max-width: 700px)'
const FeaturedImageShadowSize = theme.space[5]
const SecondaryImageShadowSize = theme.space[4]

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => `#${props.backgroundColor};`}
  width: 100%;
  height: 785px;
  align-items: center;
  justify-content: flex-start;
  padding-top: 60px;

  ${WidthThresholdMediaQuery}{
    height: 700px;
    margin-bottom: 70px;
  }
`

export const HeroTitle = styled.div`
  color: ${theme.colors.white};
  text-align: center;
  margin-top: 100px;
`

export const HeroEyebrow = styled.div`
  color: ${theme.colors.white};
  text-align: center;
`

export const HeroDescription = styled.div`
  text-align: center;
  margin-top: ${theme.space[2]} ${theme.space[2]} 0 ${theme.space[2]};
  max-width: 700px;
  margin-left: ${theme.space[4]};
  margin-right: ${theme.space[4]};
`

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: ${theme.space[6]};

  ${WidthThresholdMediaQuery} {
    justify-content: flex-end;
  }
`

export const FeaturedImage = styled(GatsbyImage)`
  height: 500px;
  z-index: 1;
  aspect-ratio: 10 / 16;
  @supports not (aspect-ratio: 10 / 16) {
    ${(props) => `height: ${props.width * 1.6}px;`}
  }

  top: 15px;

  -webkit-box-shadow: ${(props) =>
    `${FeaturedImageShadowSize} ${FeaturedImageShadowSize} 0px 0px #${props.shadowColor};`};
  box-shadow: ${(props) =>
    `${FeaturedImageShadowSize} ${FeaturedImageShadowSize} 0px 0px #${props.shadowColor};`};

  ${WidthThresholdMediaQuery} {
    margin-right: ${FeaturedImageShadowSize};
    margin-left: ${FeaturedImageShadowSize};
  }
`

export const SecondaryImage = styled(GatsbyImage)`
  height: 400px;
  z-index: 1;
  aspect-ratio: 10 / 16;
  @supports not (aspect-ratio: 10 / 16) {
    ${(props) => `height: ${props.width * 1.6}px;`}
  }

  top: 50px;

  -webkit-box-shadow: ${(props) =>
    `${SecondaryImageShadowSize} ${SecondaryImageShadowSize} 0px 0px #${props.shadowColor};`};
  box-shadow: ${(props) =>
    `${SecondaryImageShadowSize} ${SecondaryImageShadowSize} 0px 0px #${props.shadowColor};`};

  ${WidthThresholdMediaQuery} {
    width: 0px;
  }
`

export const PrimarySecondarySpacer = styled.div`
  width: 120px;

  ${WidthThresholdMediaQuery} {
    width: 0px;
  }
`

export const HeroBottomCurvedEdge = styled(BottomWhiteCurvedEdge)`
  margin-bottom: 80px;
  position: relative;
  bottom: 2.5vw;

  ${theme.mediaQueries.medium} {
    bottom: 2vw;
  }

  ${theme.mediaQueries.large} {
    bottom: 1.5vw;
  }

  ${theme.mediaQueries.xlarge} {
    bottom: 1vw;
  }

  ${WidthThresholdMediaQuery} {
    width: 0px;
    height: 0px;
  }
`

export default {
  HeroContainer,
  SubtitleContainer,
  ImagesContainer,
  HeroWrapper,
  HeroTitle,
  HeroEyebrow,
  HeroDescription,
  FeaturedImage,
  SecondaryImage,
}
